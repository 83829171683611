import { Component } from '@angular/core';

@Component({
  selector: 'folio-modals',
  template: `
    <folio-add-collection-actions-modal class="mini"></folio-add-collection-actions-modal>
    <folio-edit-collection-actions-modal class="mini"></folio-edit-collection-actions-modal>
    <folio-collection-form-modal class="mini"></folio-collection-form-modal>
    <folio-delete-collection-modal class="mini"></folio-delete-collection-modal>
    <folio-add-to-collection-modal class="mini"></folio-add-to-collection-modal>
    <folio-remove-from-collection-modal class="mini"></folio-remove-from-collection-modal>
  `
})
export class FolioModalsComponent {

}
