import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CollectionsService } from '@core/services/collections.service';
import { Router } from '@angular/router';
import { FolioActions } from '@app/ngrx/actions';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  template: '',
})
export class FolioModalComponent implements AfterViewInit {

  @HostBinding('class')
  readonly classes: string = 'ui modal';

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  public router: Router;
  public active = false;
  public elementRef: ElementRef;
  public $modal: JQuery<Element>;
  public actions$: Actions;
  public store: Store;
  public collectionsService: CollectionsService;

  constructor(
      private el: ElementRef,
      private as$: Actions,
      private st: Store,
      private cs: CollectionsService,
      private rt: Router) {
    this.elementRef         = el;
    this.actions$           = as$;
    this.store              = st;
    this.collectionsService = cs;
    this.router             = rt;
  }

  ngAfterViewInit() {
    this.$modal = $(this.elementRef.nativeElement).modal({
      onHide: () => {
        this.active = false;
        this.closeModal.emit();
      }
    });

    this.actions$.pipe(ofType(FolioActions.closeAllModals)).subscribe(() => {
      this.hide();
    });
  }

  toggle(show: boolean) {
    if (show) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.active = true;
    this.$modal.modal('show');
  }

  hide() {
    this.active = false;
    this.$modal.modal('hide');
  }
}
