/* tslint:disable:max-line-length */
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  BulkSelect,
  FetchProgress,
  FolioAuth0User,
  FolioState,
  LibraryLayout,
  LibraryState,
  Progress,
  ViewerDisplay,
  ViewerState
} from './store';
import { StateUtil } from './state.util';
import { getSelectors, RouterState } from '@ngrx/router-store';
import {
  DocumentConfig,
  Favorites,
  Filter,
  FolioConfiguration,
  FolioDocument,
  FolioDocumentRequest,
  FolioPageResult,
  LibraryFilterConfig,
  PublicDocumentLinkConfig
} from '@core/models';
import { DocUtil } from '@core/utils/doc.util';
import { FilterUtil } from '@core/utils/filter.util';
import { ld } from '@core';


/**
 * Router state selectors provided by @ngrx/router-store
 */
export const routerState = createFeatureSelector<RouterState>('router');
export const { selectCurrentRoute, selectQueryParams, selectQueryParam, selectRouteParams, selectRouteParam, selectRouteData, selectUrl } =
  getSelectors(<MemoizedSelector<any, any>> routerState);


/**
 * Folio state selectors
 */
export const selectFolio = createFeatureSelector<FolioState>('folio');
export const selectConfig = createSelector(selectFolio, (fs: FolioState) => fs.config);
export const selectLibrary = createSelector(selectFolio, (fs: FolioState) => fs.library);
export const selectViewer = createSelector(selectFolio, (fs: FolioState) => fs.viewer);
export const selectFavorites = createSelector(selectFolio, (fs: FolioState) => fs.favorites);
export const selectProgress = createSelector(selectFolio, (fs: FolioState) => fs.progress);
export const selectIndexedDocuments = createSelector(selectFolio, (fs: FolioState) => fs.indexedDocuments);
export const selectIndexedDocumentNames = createSelector(selectFolio, (fs: FolioState) => fs.indexedDocumentNames);

// config
// ------------------------------------------------

export const selectLibraryConfig = createSelector(selectConfig, (config: FolioConfiguration) => config?.globalConfig.library);
export const selectDocumentConfig = createSelector(selectConfig, (config: FolioConfiguration) => config?.globalConfig.document);
export const selectPublicDocumentLinksConfig = createSelector(selectConfig, (config: FolioConfiguration) => config?.globalConfig.publicDocumentLinks);
export const selectModalPropertiesConfig = createSelector(selectDocumentConfig, (dc: DocumentConfig) => dc?.modalProperties);
export const selectQuickFilterConfigs = createSelector(selectConfig, (config: FolioConfiguration) => config?.quickFilterConfigs);
export const selectCustomerLogo = createSelector(selectConfig, (config: FolioConfiguration) => config?.globalConfig.customerLogo);
export const selectAnnouncements = createSelector(selectConfig, (config: FolioConfiguration) => config?.announcements);


// library
// ------------------------------------------------

export const isFilterPanelShown = createSelector(selectLibrary, (ls: LibraryState) => ls.filterPanelShown);

export const selectBaseFilterConfig = createSelector(selectLibrary, (ls: LibraryState) => ls?.baseFilterConfig);
export const selectBaseSortId = createSelector(selectBaseFilterConfig, (lfc: LibraryFilterConfig) => lfc?.sortOptions.find(so => so.on).sortId);

export const selectAppliedFilterConfig = createSelector(selectLibrary, (ls: LibraryState) => ls?.appliedFilterConfig);
export const appliedFilters = createSelector(selectAppliedFilterConfig, (lfc: LibraryFilterConfig) => lfc?.filters);
export const sortOptions = createSelector(selectAppliedFilterConfig, (lfc: LibraryFilterConfig) => lfc?.sortOptions);
export const arePropertyFiltersApplied = createSelector(appliedFilters, (filters: Filter[]) => FilterUtil.areAnyFiltersApplied(filters));
export const numberOfFiltersApplied = createSelector(selectAppliedFilterConfig, (lfc: LibraryFilterConfig) => FilterUtil.numberOfFiltersApplied(lfc));
export const isOnlyFavoritesApplied = createSelector(selectAppliedFilterConfig, (lfc: LibraryFilterConfig) => lfc.onlyFavorites);

export const selectLibraryBulkSelect = createSelector(selectLibrary, (ls: LibraryState) => ls.bulkSelect);
export const isLibraryBulkSelectOn = createSelector(selectLibraryBulkSelect, (bs: BulkSelect) => bs.on);
export const libraryBulkSelectIds = createSelector(selectLibraryBulkSelect, (bs: BulkSelect) => bs.ids);
export const isLibraryDocBulkSelected = createSelector(libraryBulkSelectIds, (ids: string[], id: string) => ids.includes(id));

export const selectLibraryLayout = createSelector(selectLibrary, (ls: LibraryState) => ls.layout);
export const isLibraryCardLayout = createSelector(selectLibraryLayout, (ly: LibraryLayout) => ly === LibraryLayout.CARD_VIEW);

export const selectLibraryPageResult = createSelector(selectLibrary, (ls: LibraryState) => ls.folioPageResult);
export const selectLibraryPagingData = createSelector(selectLibraryPageResult, (fpr: FolioPageResult) => FilterUtil.getLibraryPagingData(fpr));
export const selectLibraryPageResultDocs = createSelector(selectLibraryPageResult, (pageResult: FolioPageResult) => pageResult?.folioDocuments);

export const selectAppliedRequest = createSelector(selectLibrary, (ls: LibraryState) => ls.appliedRequest);
export const selectAppliedFreeText = createSelector(selectAppliedRequest, (fdr: FolioDocumentRequest) => fdr?.folioFilters?.freeText);
export const isFreeTextSearchApplied = createSelector(selectAppliedFreeText, (ft: string) => !!ft?.length);

export const areAllLibraryDocsBulkSelected =
  createSelector(libraryBulkSelectIds, selectLibraryPageResultDocs, (bulkIds: string[], docs: FolioDocument[]) => {
    if (!bulkIds || bulkIds.length === 0) {
      return false;
    }
    const docIds = docs?.map(doc => DocUtil.getId(doc));
    if (!docIds || docIds.length === 0) {
      return false;
    }
    return ld.intersection((docIds || []), (bulkIds || [])).length === docIds.length;
  });


// viewer
// ------------------------------------------------

export const selectViewerDoc = createSelector(selectViewer, (vs: ViewerState) => vs.document);
export const selectViewerDisplay = createSelector(selectViewer, (vs: ViewerState) => vs.display);
export const selectViewerPresentationMode = createSelector(selectViewerDisplay, (vd: ViewerDisplay) => vd.presentationMode);
export const selectViewerZoomScale = createSelector(selectViewerDisplay, (vd: ViewerDisplay) => vd.zoomScale);
export const selectViewerFitToWidth = createSelector(selectViewerDisplay, (vd: ViewerDisplay) => vd.fitToWidth);
export const selectViewerPageData = createSelector(selectViewerDisplay, (vd: ViewerDisplay) => vd.pageData);


// public document links (aka shares)
// ------------------------------------------------

export const selectPublicLinksEnabled = createSelector(selectPublicDocumentLinksConfig, (p: PublicDocumentLinkConfig) => p?.enabled);
export const selectPublicLinksExpireAfterDays = createSelector(selectPublicDocumentLinksConfig, (p: PublicDocumentLinkConfig) => p?.expireAfterDays);


// progress
// ------------------------------------------------

export const selectConfigProgress = createSelector(selectProgress, (p: Progress) => p.config);
export const selectLibraryDocsProgress = createSelector(selectProgress, (p: Progress) => p.libraryDocuments);
export const selectViewerDocProgress = createSelector(selectProgress, (p: Progress) => p.viewerDocument);

export const isConfigLoaded = createSelector(selectConfigProgress, (fp: FetchProgress) => StateUtil.isLoaded(fp));
export const areDocumentsLoading = createSelector(selectLibraryDocsProgress, (fp: FetchProgress) => StateUtil.isLoading(fp));
export const areDocumentsLoaded = createSelector(selectLibraryDocsProgress, (fp: FetchProgress) => StateUtil.isLoaded(fp));
export const isActiveDocLoaded = createSelector(selectViewerDocProgress, (fp: FetchProgress) => StateUtil.isLoaded(fp));


// favorites
// ------------------------------------------------

export const isDocAFavorite = createSelector(selectFavorites, (favorites: Favorites, doc: FolioDocument) => {
  return !favorites ? false : (favorites.documentIds || []).includes(DocUtil.getId(doc));
});


// user
// ------------------------------------------------

export const selectUser = createSelector(selectFolio, (fs: FolioState) => fs.user);
export const selectUserId = createSelector(selectUser, (u: FolioAuth0User) => u['http://pepperapps.io/uuid']);
export const isUserSet = createSelector(selectUser, (user: FolioAuth0User) => !!user);

export const canUserDownloadContent =
    createSelector(selectUser, (user: FolioAuth0User) => user?.accessToken?.permissions?.includes('download:content'));
export const canUserFavoriteContent =
    createSelector(selectUser, (user: FolioAuth0User) => user?.accessToken?.permissions?.includes('favorite:content'));
export const canUserShareContent =
    createSelector(selectUser, selectPublicLinksEnabled, (user: FolioAuth0User, enabled: boolean) => {
      return enabled && user?.accessToken?.permissions?.includes('share:content');
    });
export const canUserCreateCollection =
    createSelector(selectUser, selectPublicLinksEnabled, (user: FolioAuth0User, enabled: boolean) => {
      return enabled && user?.accessToken?.permissions?.includes('create:curation');
    });
export const canUserDeleteCollection =
    createSelector(selectUser, selectPublicLinksEnabled, (user: FolioAuth0User, enabled: boolean) => {
      return enabled && user?.accessToken?.permissions?.includes('delete:curation');
    });
