import { createAction, props } from '@ngrx/store';
import { FolioLibraryQueryParam, LibraryLayout, PageData } from './store';
import {
  AppliedFilterLabel,
  Favorites,
  FolioConfiguration,
  FolioDocument,
  FolioPageResult,
  PendingFilters
} from '@core/models';
import { FolioModalType } from '@core/models/modals.model';

export const FolioActions = {
  // user
  // ------------------------------------------------
  setUser: createAction('[FOLIO] Set User', props<{user: any, jwt: string}>()),

  // config
  // ------------------------------------------------
  getConfiguration:        createAction('[FOLIO] Get Configuration'),
  getConfigurationSuccess: createAction('[FOLIO] Get Config Success', props<{config: FolioConfiguration}>()),

  // modals
  // ------------------------------------------------
  toggleModal:    createAction('[FOLIO] Toggle Modal', props<{modalType: FolioModalType, show?: boolean, data?: any}>()),
  closeAllModals: createAction('[FOLIO] Close All Modals'),

  // home
  // ------------------------------------------------
  loadHome: createAction('[FOLIO] Load Home'),

  // share history
  // ------------------------------------------------
  loadShareHistory: createAction('[FOLIO] Load Share History'),

  // library
  // ------------------------------------------------
  loadLibrary:    createAction('[FOLIO] Load Library'),
  getLibraryDocs: createAction('[FOLIO] Get Library Documents'),

  updateLibraryQueryParam: createAction('[FOLIO] Update Library Query Param', props<{qParam: FolioLibraryQueryParam}>()),
  updateStateFromParams:   createAction('[FOLIO] Update State From Library Query Param', props<{qParam: FolioLibraryQueryParam}>()),

  queryLibraryDocs:        createAction('[FOLIO] Query Library Documents'),
  queryLibraryDocsSuccess: createAction('[FOLIO] Query Library Documents Success', props<{folioPageResult: FolioPageResult}>()),

  applyPendingFreeText:     createAction('[FOLIO] Apply Pending Library Free Text', props<{freeText: string}>()),
  clearAppliedFreeText:     createAction('[FOLIO] Clear Applied Library Free Text'),
  applyPendingFilters:      createAction('[FOLIO] Apply Pending Library Filters', props<{pendingFilters: PendingFilters}>()),
  clearAppliedFilters:      createAction('[FOLIO] Clear Applied Library Filters'),
  clearAppliedFilter:       createAction('[FOLIO] Clear Applied Library Filter', props<{appliedFilterLabel: AppliedFilterLabel}>()),
  clearOnlyFavoritesFilter: createAction('[FOLIO] Clear Only Favorites Filter'),

  getNextLibraryDocs:     createAction('[FOLIO] Get Next Library Documents'),
  getPreviousLibraryDocs: createAction('[FOLIO] Get Previous Library Documents'),

  applySortOption: createAction('[FOLIO] Apply Sort', props<{sortId: string}>()),

  showLibraryFilters: createAction('[FOLIO] Show Library Filters'),
  hideLibraryFilters: createAction('[FOLIO] Hide Library Filters'),

  toggleLibraryLayoutView: createAction('[FOLIO] Toggle Library Layout View', props<{layout: LibraryLayout}>()),

  enterLibraryBulkSelect:   createAction('[FOLIO] Enter Library Bulk Select'),
  exitLibraryBulkSelect:    createAction('[FOLIO] Exit Library Bulk Select'),
  bulkSelectLibraryDoc:     createAction('[FOLIO] Bulk Select Library Doc', props<{id: string, on: boolean}>()),
  bulkSelectAllLibraryDocs: createAction('[FOLIO] Bulk Select All Library Docs', props<{on: boolean}>()),

  downloadDocument: createAction('[FOLIO] Download Document', props<FolioDocument>()),

  indexDocument: createAction('[FOLIO] Index Document', props<FolioDocument>()),

  // favorites
  // ------------------------------------------------
  getUsersFavorites:        createAction('[FOLIO] Get Users Favorites'),
  getUsersFavoritesSuccess: createAction('[FOLIO] Get Users Favorites Success', props<{favorites: Favorites}>()),

  toggleFavorite: createAction('[FOLIO] Toggle Favorite', props<{id: string}>()),
  addFavorite:    createAction('[FOLIO] Add Favorite', props<{id: string}>()),
  removeFavorite: createAction('[FOLIO] Remove Favorite', props<{id: string}>()),

  // viewer
  // ------------------------------------------------
  loadViewer:                createAction('[FOLIO] Load Viewer'),
  loadPublicViewer:          createAction('[FOLIO] Load Public Viewer'),
  getViewerDoc:              createAction('[FOLIO] Get Document'),
  getPublicViewerDoc:        createAction('[FOLIO] Get Public Document'),
  getViewerDocSuccess:       createAction('[FOLIO] Get Document Success', props<{document: FolioDocument}>()),
  getPublicViewerDocSuccess: createAction('[FOLIO] Get Public Document Success', props<{document: FolioDocument}>()),

  resetViewer: createAction('[FOLIO] Reset Viewer'),

  setViewerContentReady:     createAction('[FOLIO] Set Viewer Content Ready', props<{contentReady: boolean}>()),
  setViewerPresentationMode: createAction('[FOLIO] Set Viewer Presentation Mode', props<{presentationMode: boolean}>()),
  setViewerPageData:         createAction('[FOLIO] Set Viewer Page Data', props<{pageData: PageData}>()),
  setViewerZoom:             createAction('[FOLIO] Set Viewer Zoom', props<{scale: number}>()),
  incrementViewerZoom:       createAction('[FOLIO] Increment Viewer Zoom'),
  decrementViewerZoom:       createAction('[FOLIO] Decrement Viewer Zoom'),
  setViewerFitToWidth:       createAction('[FOLIO] Set Viewer Fit To Width', props<{fitToWidth: boolean}>()),

  // profile
  // ------------------------------------------------
  loadProfile: createAction('[FOLIO] Load Profile'),
};
