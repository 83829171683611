export interface FolioConfiguration {
  globalConfig: GlobalConfig;
  quickFilterConfigs: QuickFilter[];
  announcements: Announcement[];
}

export interface GlobalConfig {
  customerLogo: string;
  library: LibraryFilterConfig;
  document: DocumentConfig;
  publicDocumentLinks: PublicDocumentLinkConfig;
}

export interface QuickFilter {
  display: string;
  filters: {
    filterId: string;
    optionDisplays: string[];
  }[];
}

export interface Announcement {
  id: string;
  sys: {
    [s: string]: string;
  };
  fields: {
    startDate: string;
    endDate: string;
    message: string;
    labels: {
      title?: string;
      [s: string]: string;
    }
  };
}

export interface LibraryFilterConfig {
  filters: Filter[];
  sortOptions: SortOption[];
  // onlyFavorites is not actually configured in JSON, but will live here in the store
  // since the applied LibraryFilterConfig is what we look at to generate our RSQL
  onlyFavorites: boolean;
}

export interface DocumentConfig {
  modalProperties: DocumentProperty[];
  permissions?: {
    [s: string]: string
  };
}

export interface DocumentProperty {
  key: string;
  typeHint?: string;
  fallbackLabel?: string;
  overrideLabel?: string;
}

export interface PublicDocumentLinkConfig {
  enabled: boolean;
  expireAfterDays: number;
}

export interface SortOption {
  sortId: string;
  on: boolean;
  display: string;
  value: {
    attribute: string;
    descending: boolean;
  };
  hidden?: boolean;
}

export interface Filter {
  filterId: string;
  type: FilterType;
  label: string;
  fieldPath: string;
  helpText?: string;
}

export enum FilterType {
  CHECKBOX_GROUP = <any> 'checkbox-group',
  MULTI_SELECT = <any> 'multi-select'
}

export interface CheckboxFilter extends Filter {
  options: CheckboxFilterOption[];
}

export interface CheckboxFilterOption {
  value: string[];
  display: string;
  checked?: boolean;
}

export interface MultiSelectFilter extends Filter {
  options: MultiSelectFilterOption[];
  selectedValues?: string[];
}

export interface MultiSelectFilterOption {
  value: string[];
  display: string;
  selected?: boolean;
}

export interface AppliedFilterLabel {
  filterId: string;
  filterLabel: string;
  optionDisplay: string;
}

export interface FilterKeyOptionMap {
  [s: string]: string[];
}

export interface PendingFilters {
  filterKeyOptions: FilterKeyOptionMap;
  onlyFavorites: boolean;
}
