/**
 * Response from the Folio API when querying for documents
 */
export interface FolioPageResult {
  folioDocuments: FolioDocument[];
  totalResults: number;
  start: number;
  count: number;
}

export interface FolioDocument {
  publicMetadata: FolioDocumentMetaDatas;
  systemMetadata: FolioDocumentMetaDatas;
  customerMetadata: FolioDocumentMetaDatas;
  permissionMetadata: FolioDocumentMetaDatas;
}

export interface FolioDocumentMetaDatas {
  [s: string]: LabelValue;
}

// called FolioDocumentMetadata in the Folio API
export interface LabelValue {
  label: string;
  value: string | string[];
}

// extension of LabelValue to give the templates some hints for displaying
export interface LabelValueType extends LabelValue {
  type: PropertyType;
  hasLongValue?: boolean;
  isExpirationDate?: boolean;
}

export enum PropertyType {
  STRING = 'string',
  ARRAY = 'array',
  DATE = 'date',
  FILESIZE = 'filesize',
  UNKNOWN = 'unknown'
}

export interface FolioDocumentMetrics {
  views: number;
  internalViews: number;
  sharedLinkViews: number;
  downloads: number;
  favorites: number;
  shares: number;
}

export class FolioDocumentRequest {
  folioFilters: RequestFilters;
  folioPaging: RequestPaging;
  folioSort: RequestSort;

  constructor(args: {
    filters?: RequestFilters;
    paging?: RequestPaging;
    sort?: RequestSort
  } = {
    filters: new RequestFilters(),
    paging:  new RequestPaging(),
    sort:    new RequestSort()
  }) {
    this.folioFilters = args.filters ? args.filters : new RequestFilters();
    this.folioPaging  = args.paging ? args.paging : new RequestPaging();
    this.folioSort    = args.sort ? args.sort : new RequestSort();
  }
}

// what the Folio API stores in mongo to represent a doc that has been made public
export class FolioPublicDocumentLink {
  id: string;
  timestamp: string;
  shareType: FolioPublicDocumentLinkShareType;
  documentId: string;
  documentName: string;
  creator: string;
  to?: [string];
  lastViewed?: string;
  expirationDate?: string;
  views?: number;
  expired?: boolean;
}

class Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export class FolioPublicDocumentLinksPage {
  content: FolioPublicDocumentLink[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  first: boolean;
  last: boolean;
  pageNumber: number;
  empty: boolean;
}

export enum FolioPublicDocumentLinkShareType {
  LINK = 'LINK',
  EMAIL = 'EMAIL'
}

// the request sent to the Folio API to create a new FolioPublicDocumentLink in mongo
export class FolioPublicDocumentLinkRequest {
  shareType: FolioPublicDocumentLinkShareType;
  documentId: string;
  to?: [string];
}

export class FolioPublicDocumentLinkResponse {
  created: boolean;
  folioPublicDocumentLink: FolioPublicDocumentLink;
}

export class RequestFilters {
  freeText: string;
  rsqlQuery: string;

  constructor(freeText: string = null, rsqlQuery: string = null) {
    this.freeText  = freeText;
    this.rsqlQuery = rsqlQuery;
  }
}

export const BASE_PAGING_COUNT = 48;
export const BASE_COLLECTION_COUNT = 500;

export class RequestPaging {
  count: number;
  start: number;

  constructor(count: number = BASE_PAGING_COUNT, start = 0) {
    this.count = count;
    this.start = start;
  }
}

export class RequestSort {
  attribute: string;
  descending: boolean;

  constructor(attribute = '', descending = true) {
    this.attribute  = attribute;
    this.descending = descending;
  }
}

export enum ViewerType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  HTML5 = 'HTML5',
  UNSUPPORTED = 'UNSUPPORTED'
}

export interface SearchSuggestions {
  terms: string[];
  documents: FolioDocument[];
}

export interface LibraryPagingData {
  from: number;
  to: number;
  total: number;
  hasPrevious: boolean;
  hasNext: boolean;
}
