import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { WithMainMenuComponent } from './components/with-main-menu/with-main-menu.component';
import { IsBulkSelectedPipe } from './pipes/is-bulk-selected.pipe';
import { DocumentActionsModalComponent } from './components/modals/document-actions-modal/document-actions-modal.component';
import { DocumentLinkModalComponent } from './components/modals/document-link-modal/document-link-modal.component';
import { DocumentDetailsModalComponent } from './components/modals/document-details-modal/document-details-modal.component';
import { IsNotPipe } from './pipes/is-not.pipe';
import {
  DocApprovalDatePipe,
  DocDisplayNamePipe,
  DocDisplayIdPipe,
  DocExpirationDatePipe,
  DocFileNamePipe,
  DocFileSizePipe,
  DocShareable,
  DocIsShareable,
  DocNotShareableMessage,
  DocFileUrlPipe,
  DocDownloadUrlPipe,
  DocCustomerLogoPipe,
  DocGeneralizedMimeTypePipe,
  DocIdPipe,
  DocImageUrlPipe,
  DocMimeTypePipe,
  DocThumbnailUrlPipe,
  DocTypeDisplayPipe,
  DocVersionPipe
} from './pipes/doc-properties.pipe';
import { ViewerTypePipe } from './pipes/viewer-type.pipe';
import { IconMessageComponent } from './components/icon-message/icon-message.component';
import { IsFavoritePipe } from './pipes/is-favorite.pipe';
import { CsvPipe } from './pipes/csv.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { WithinDaysPipe } from './pipes/within-days.pipe';
import { UserEmailPipe, UserFullNamePipe } from './pipes/user.pipe';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { SafePipe } from './pipes/safe.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SearchMenuComponent } from './components/search-menu/search-menu.component';
import { SearchInputComponent } from './components/search-menu/search-input/search-input.component';
import { PdfViewerComponent } from './components/viewer/pdf-viewer/pdf-viewer.component';
import { PdfPresentationViewerComponent } from './components/viewer/pdf-viewer/presentation/pdf-presentation-viewer.component';
import { ImageViewerComponent } from './components/viewer/image-viewer/image-viewer.component';
import { VideoViewerComponent } from './components/viewer/video-viewer/video-viewer.component';
import { UnsupportedViewerComponent } from './components/viewer/unsupported-viewer/unsupported-viewer.component';
import { Html5ViewerComponent } from './components/viewer/html5-viewer/html5-viewer.component';
import { ViewerTopMenuComponent } from '../features/viewer/top-menu/viewer-top-menu.component';
import { ViewerBottomMenuComponent } from '../features/viewer/bottom-menu/viewer-bottom-menu.component';
import { PublicUrlConsentComponent } from './components/consent/public-url-consent.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NoSharesComponent } from './components/no-shares/no-shares.component';
import { StripHighlightsPipe } from './pipes/strip-highlights.pipe';
import { BaseInputComponent } from './form-inputs/text-input/text-input.component';
import { CheckboxInputComponent } from './form-inputs/checkbox-input/checkbox-input.component';
import { SelectInputComponent } from './form-inputs/select-input/select-input.component';
import { LibraryListItemComponent } from './library-item/row/library-list-item.component';
import { LibraryCardComponent } from './library-item/card/library-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionRowComponent } from './collection-display-item/row/collection-row.component';
import { CollectionCardComponent } from './collection-display-item/card/collection-card.component';
import { CollectionDisplayComponent } from './collection-display-item/collection-display.component';
import { FolioModalsComponent } from './folio-modals/folio-modals.component';
import { FolioModalComponent } from './folio-modals/folio-modal/folio-modal.component';
import { DeleteCollectionModalComponent } from './folio-modals/modals/delete-collection-modal/delete-collection-modal..component';
import { AddCollectionActionsModalComponent } from './folio-modals/modals/add-collection-actions-modal/add-collection-actions-modal.component';
import { CollectionFormModalComponent } from './folio-modals/modals/collection-form-modal/collection-form-modal.component';
import { EditCollectionActionsModalComponent } from './folio-modals/modals/edit-collection-actions-modal/edit-collection-actions-modal.component';
import {
  AddToCollectionModalComponent
} from '@shared/folio-modals/modals/add-to-collection-modal/add-to-collection-modal.component';
import {
  RemoveFromCollectionModalComponent
} from '@shared/folio-modals/modals/remove-from-collection-modal/remove-from-collection-modal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([])
  ],
  declarations: [
    MainMenuComponent,
    WithMainMenuComponent,
    DocumentActionsModalComponent,
    DocumentLinkModalComponent,
    DocumentDetailsModalComponent,
    IconMessageComponent,
    LoaderComponent,
    IsBulkSelectedPipe,
    IsFavoritePipe,
    IsNotPipe,
    DocIdPipe,
    DocVersionPipe,
    DocDisplayNamePipe,
    DocDisplayIdPipe,
    DocFileNamePipe,
    DocExpirationDatePipe,
    DocTypeDisplayPipe,
    DocFileUrlPipe,
    DocDownloadUrlPipe,
    DocCustomerLogoPipe,
    DocFileSizePipe,
    DocImageUrlPipe,
    DocThumbnailUrlPipe,
    DocMimeTypePipe,
    DocGeneralizedMimeTypePipe,
    DocApprovalDatePipe,
    DocShareable,
    DocIsShareable,
    DocNotShareableMessage,
    ViewerTypePipe,
    CsvPipe,
    WithinDaysPipe,
    UserFullNamePipe,
    UserEmailPipe,
    TruncatePipe,
    ErrorModalComponent,
    SafePipe,
    SafeUrlPipe,
    SearchMenuComponent,
    SearchInputComponent,
    PdfViewerComponent,
    PdfPresentationViewerComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    UnsupportedViewerComponent,
    Html5ViewerComponent,
    ViewerTopMenuComponent,
    ViewerBottomMenuComponent,
    PublicUrlConsentComponent,
    NoSharesComponent,
    StripHighlightsPipe,
    AnnouncementsComponent,
    BaseInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    LibraryListItemComponent,
    LibraryCardComponent,
    AddToCollectionModalComponent,
    RemoveFromCollectionModalComponent,
    CollectionDisplayComponent,
    CollectionCardComponent,
    CollectionRowComponent,
    FolioModalsComponent,
    FolioModalComponent,
    DeleteCollectionModalComponent,
    AddCollectionActionsModalComponent,
    CollectionFormModalComponent,
    EditCollectionActionsModalComponent,
  ],
  exports: [
    MainMenuComponent,
    WithMainMenuComponent,
    DocumentActionsModalComponent,
    DocumentLinkModalComponent,
    DocumentDetailsModalComponent,
    ErrorModalComponent,
    IconMessageComponent,
    LoaderComponent,
    IsBulkSelectedPipe,
    IsFavoritePipe,
    IsNotPipe,
    DocIdPipe,
    DocVersionPipe,
    DocDisplayNamePipe,
    DocDisplayIdPipe,
    DocFileNamePipe,
    DocExpirationDatePipe,
    DocTypeDisplayPipe,
    DocFileSizePipe,
    DocFileUrlPipe,
    DocDownloadUrlPipe,
    DocCustomerLogoPipe,
    DocImageUrlPipe,
    DocThumbnailUrlPipe,
    DocMimeTypePipe,
    DocGeneralizedMimeTypePipe,
    DocApprovalDatePipe,
    DocShareable,
    DocIsShareable,
    DocNotShareableMessage,
    ViewerTypePipe,
    CsvPipe,
    WithinDaysPipe,
    UserFullNamePipe,
    UserEmailPipe,
    TruncatePipe,
    SafePipe,
    SafeUrlPipe,
    StripHighlightsPipe,
    SearchMenuComponent,
    SearchInputComponent,
    PdfViewerComponent,
    PdfPresentationViewerComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    UnsupportedViewerComponent,
    Html5ViewerComponent,
    ViewerTopMenuComponent,
    ViewerBottomMenuComponent,
    PublicUrlConsentComponent,
    NoSharesComponent,
    AnnouncementsComponent,
    BaseInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    LibraryListItemComponent,
    LibraryCardComponent,
    AddToCollectionModalComponent,
    RemoveFromCollectionModalComponent,
    CollectionDisplayComponent,
    CollectionCardComponent,
    CollectionRowComponent,
    FolioModalsComponent,
    DeleteCollectionModalComponent,
    AddCollectionActionsModalComponent,
    CollectionFormModalComponent,
  ]
})
export class SharedModule {
}
