import { Action, createReducer, on } from '@ngrx/store';
import { FolioActions } from './actions';
import { FetchProgress, FolioState, initialState } from './store';
import { DataUtil } from '@core/utils/data.util';
import { DocUtil } from '@core/utils/doc.util';
import { FilterUtil } from '@core/utils/filter.util';
import { BASE_PAGING_COUNT, FolioDocumentRequest } from '@core/models';
import { StateUtil } from './state.util';
import jwt_decode from 'jwt-decode';
import { ld } from '@core';

const folioReducer = createReducer(
  initialState,

  /**
   * User
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.setUser, (state: FolioState, { user, jwt }) => {
    const newState: FolioState = DataUtil.clone(state);
    newState.user              = { ...user, accessToken: jwt_decode(jwt) };
    return newState;
  }),

  /**
   * Config
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.getConfiguration, (state: FolioState) => {
    const newState: FolioState = DataUtil.clone(state);
    if (newState.progress.config !== FetchProgress.LOADED) {
      newState.progress.config = FetchProgress.LOADING;
    }
    return newState;
  }),
  on(FolioActions.getConfigurationSuccess, (state: FolioState, { config }) => {
    const newState: FolioState = DataUtil.clone(state);
    newState.config            = DataUtil.clone(config);
    newState.progress.config   = FetchProgress.LOADED;

    newState.library.baseFilterConfig    = DataUtil.clone(config.globalConfig.library);
    newState.library.appliedFilterConfig = DataUtil.clone(config.globalConfig.library);

    // add relevancy sort option
    newState.library.baseFilterConfig.sortOptions.push(DataUtil.clone(FilterUtil.RELEVANCY_SORT_OPTION));
    newState.library.appliedFilterConfig.sortOptions.push(DataUtil.clone(FilterUtil.RELEVANCY_SORT_OPTION));

    const initialRequest            = new FolioDocumentRequest();
    newState.library.baseRequest    = DataUtil.clone(initialRequest);
    newState.library.appliedRequest = DataUtil.clone(initialRequest);

    return newState;
  }),

  /**
   * Library
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.updateStateFromParams, (state: FolioState, { qParam }) => {
    const newState: FolioState = DataUtil.clone(state);

    // the qParam-specified favorites
    // ----------------------------------------
    newState.library.appliedFilterConfig.onlyFavorites = qParam.favorites ?? false;

    // the qParam-specified filters
    // ----------------------------------------
    newState.library.appliedFilterConfig.filters =
      FilterUtil.getFiltersWithPendingApplied(newState.library.baseFilterConfig.filters, qParam.filters ?? {});
    newState.library.appliedRequest.folioFilters.rsqlQuery = FilterUtil.getRSQLForAppliedFilters(newState);

    // the qParam-specified paging
    // ----------------------------------------
    newState.library.appliedRequest.folioPaging = {
      start: qParam.start ? qParam.start : 0,
      count: BASE_PAGING_COUNT
    };

    // the qParam-specified free text
    // ----------------------------------------
    newState.library.appliedRequest.folioFilters.freeText = qParam.text;
    // FolioFilters has a place for a freeText property but we also want to include it in the rsql expression
    newState.library.appliedRequest.folioFilters.rsqlQuery = FilterUtil.getRSQLForAppliedFilters(newState);

    // the qParam-specified sort
    // ----------------------------------------
    const sortId = qParam.sort || FilterUtil.getBaseSortId(state.library);
    newState.library.appliedFilterConfig.sortOptions.forEach(so => so.on = so.sortId === sortId);
    newState.library.appliedFilterConfig.sortOptions.find(so => so.sortId === 'relevancy').hidden = !qParam.text;
    if (!newState.library.appliedFilterConfig.sortOptions.find(so => so.on)) {
      // if we've hidden 'relevancy' it's possible no sort will be 'on', so set it to the default
      const defaultSort = newState.library.baseFilterConfig.sortOptions.find(so => so.on);

      newState.library.appliedFilterConfig.sortOptions.find(so => so.sortId === defaultSort.sortId).on = true;
    }
    newState.library.appliedRequest.folioSort = FilterUtil.getRequestSortBasedOnOptions(newState.library);

    return newState;
  }),
  on(FolioActions.queryLibraryDocs, (state: FolioState) => {
    const newState: FolioState         = DataUtil.clone(state);
    newState.progress.libraryDocuments = FetchProgress.LOADING;
    return newState;
  }),
  on(FolioActions.queryLibraryDocsSuccess, (state: FolioState, { folioPageResult }) => {
    const newState: FolioState         = DataUtil.clone(state);
    newState.library.folioPageResult   = folioPageResult;
    newState.progress.libraryDocuments = FetchProgress.LOADED;
    newState.indexedDocumentNames      = newState.indexedDocumentNames ?? {};
    DocUtil.addToDocumentNameIndex(newState.indexedDocumentNames, folioPageResult?.folioDocuments);
    return newState;
  }),

  /**
   * Indexed documents
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.indexDocument, (state: FolioState, folioDocument) => {
    const newState: FolioState = DataUtil.clone(state);
    newState.indexedDocuments  = newState.indexedDocuments ?? {};
    DocUtil.addToDocumentIndex(newState.indexedDocuments, [folioDocument]);
    return newState;
  }),

  /**
   * Favorites
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.getUsersFavoritesSuccess, (state: FolioState, { favorites }) => {
    const newState: FolioState  = DataUtil.clone(state);
    newState.favorites          = favorites;
    newState.progress.favorites = FetchProgress.LOADED;
    return newState;
  }),

  /**
   * Toggle Library layout view
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.toggleLibraryLayoutView, (state: FolioState, { layout }) => {
    const newState: FolioState = DataUtil.clone(state);
    newState.library.layout    = layout;
    return newState;
  }),

  /**
   * Viewer
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.getViewerDoc, (state: FolioState) => {
    const newState: FolioState       = DataUtil.clone(state);
    newState.viewer.document         = null;
    newState.progress.viewerDocument = FetchProgress.LOADING;
    return newState;
  }),
  on(FolioActions.getViewerDocSuccess, FolioActions.getPublicViewerDocSuccess, (state: FolioState, { document }) => {
    const newState: FolioState       = DataUtil.clone(state);
    newState.viewer.document         = document;
    newState.progress.viewerDocument = FetchProgress.LOADED;
    return newState;
  }),
  on(FolioActions.resetViewer, (state: FolioState) => {
    const newState: FolioState       = DataUtil.clone(state);
    newState.viewer.document         = null;
    newState.viewer.display          = { presentationMode: false, zoomScale: 1, fitToWidth: false, pageData: null };
    newState.progress.viewerDocument = FetchProgress.NONE;
    return newState;
  }),
  on(FolioActions.setViewerPresentationMode, (state: FolioState, { presentationMode }) => {
    const newState: FolioState               = DataUtil.clone(state);
    newState.viewer.display.presentationMode = presentationMode;
    newState.viewer.display.zoomScale        = 1;
    newState.viewer.display.fitToWidth       = false;
    return newState;
  }),
  on(FolioActions.setViewerContentReady, (state: FolioState, { contentReady }) => {
    const newState: FolioState           = DataUtil.clone(state);
    newState.viewer.display.contentReady = contentReady;
    return newState;
  }),
  on(FolioActions.setViewerPageData, (state: FolioState, { pageData }) => {
    const newState: FolioState       = DataUtil.clone(state);
    newState.viewer.display.pageData = pageData;
    return newState;
  }),
  on(FolioActions.setViewerZoom, (state: FolioState, { scale }) => {
    const newState: FolioState        = DataUtil.clone(state);
    newState.viewer.display.zoomScale = scale;
    return newState;
  }),
  on(FolioActions.incrementViewerZoom, (state: FolioState) => {
    const newState: FolioState         = DataUtil.clone(state);
    newState.viewer.display.zoomScale  = StateUtil.incrementScale(state.viewer.display.zoomScale);
    newState.viewer.display.fitToWidth = false;
    return newState;
  }),
  on(FolioActions.decrementViewerZoom, (state: FolioState) => {
    const newState: FolioState         = DataUtil.clone(state);
    newState.viewer.display.zoomScale  = StateUtil.decrementScale(state.viewer.display.zoomScale);
    newState.viewer.display.fitToWidth = false;
    return newState;
  }),
  on(FolioActions.setViewerFitToWidth, (state: FolioState, { fitToWidth }) => {
    const newState: FolioState         = DataUtil.clone(state);
    newState.viewer.display.zoomScale  = 1;
    newState.viewer.display.fitToWidth = fitToWidth;
    return newState;
  }),


  /**
   * Hide/show filter panel
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.showLibraryFilters, (state: FolioState) => {
    const newState: FolioState        = DataUtil.clone(state);
    newState.library.filterPanelShown = true;
    return newState;
  }),
  on(FolioActions.hideLibraryFilters, (state: FolioState) => {
    const newState: FolioState        = DataUtil.clone(state);
    newState.library.filterPanelShown = false;
    return newState;
  }),


  /**
   * Bulk select
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  on(FolioActions.enterLibraryBulkSelect, (state: FolioState) => {
    const newState: FolioState     = DataUtil.clone(state);
    newState.library.bulkSelect.on = true;
    return newState;
  }),
  on(FolioActions.bulkSelectLibraryDoc, (state: FolioState, { id, on }) => {
    const newState: FolioState = DataUtil.clone(state);
    const idSet: Set<string>   = new Set(newState.library.bulkSelect.ids);
    if (on) {
      idSet.add(id);
    } else {
      idSet.delete(id);
    }
    newState.library.bulkSelect.ids = Array.from(idSet);
    return newState;
  }),
  on(FolioActions.bulkSelectAllLibraryDocs, (state: FolioState, { on }) => {
    const newState: FolioState      = DataUtil.clone(state);
    const currentSelectedIds        = newState.library.bulkSelect.ids;
    const currentPageIds            = newState.library.folioPageResult?.folioDocuments?.map(d => DocUtil.getId(d));
    const newlySelectedIds          = on ?
      // if they selected all, just combine them and de-dedupe later
      (currentSelectedIds || []).concat(currentPageIds) :
      // if they unchecked all, remove all the current page docs from the list of selected
      currentSelectedIds.filter(csid => !currentPageIds.includes(csid));
    newState.library.bulkSelect.ids = ld.uniq(newlySelectedIds);
    return newState;
  }),
  on(FolioActions.exitLibraryBulkSelect, (state: FolioState) => {
    const newState: FolioState  = DataUtil.clone(state);
    newState.library.bulkSelect = { on: false, ids: [] };
    return newState;
  }),
);

export function reducer(state: FolioState | undefined, action: Action) {
  return folioReducer(state, action);
}
