import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, ActionCreator, select, Store } from '@ngrx/store';
import { ROUTER_NAVIGATION, RouterNavigationPayload } from '@ngrx/router-store';
import { FolioLibraryQueryParam, FolioState } from './store';
import { FolioActions } from './actions';
import * as FolioSelectors from './selectors';
import { DocumentService } from '@core/services/document.service';
import { ConfigService } from '@core/services/config.service';
import { MessagingService } from '@core/services/messaging.service';
import { AuditService } from '@core/services/audit.service';
import { AuditActions } from '@core/models/audit.model';
import { AppliedFilterLabel, BASE_PAGING_COUNT, FolioDocument, PendingFilters } from '@core/models';
import { DataUtil } from '@core/utils/data.util';
import { FilterUtil } from '@core/utils/filter.util';
import { DocUtil } from '@core/utils/doc.util';
import { UrlUtil } from '@core/utils/url.util';
import { of } from 'rxjs';
import { FavoritesService } from '@core/services/favorites.service';
import { CollectionsService } from '@core/services/collections.service';

@Injectable()
export class FolioEffects {

  constructor(
    private store: Store<FolioState>,
    private actions$: Actions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private favoritesService: FavoritesService,
    private collectionsService: CollectionsService,
    private documentService: DocumentService,
    private messagingService: MessagingService,
    private auditService: AuditService) {
  }

  /**
   * Router navigation
   * - dispatch the actions defined for the current route. (See app-routing.modules.ts)
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  routerNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    delayWhen(() => {
      if (UrlUtil.isPubliclyAccessiblyPage()) {
        return of(true);
      } else {
        return this.store.pipe(
          select(FolioSelectors.isUserSet),
          filter(isUserSet => isUserSet)
        );
      }
    }),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectRouteData))),
    mergeMap(([_, routeData]) => {
      const actionCreators = <ActionCreator[]> routeData.actions || [];
      const routeActions   = <Action[]> actionCreators.map(routerAction => routerAction());
      this.messagingService.clearDocumentModals();
      // any state clearing actions needed when routing or any actions based on navigation extras
      const turnOnBulkSelect = this.router.getCurrentNavigation()?.extras?.state?.bulkSelectOn;
      if (turnOnBulkSelect) {
        routeActions.push(FolioActions.enterLibraryBulkSelect());
      }
      return [
        FolioActions.closeAllModals(),
        FolioActions.resetViewer(),
        ...routeActions
      ];
    })
  ));

  /**
   * Config
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  getConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getConfiguration),
    withLatestFrom(this.store.pipe(select(FolioSelectors.isConfigLoaded))),
    filter(([_, isConfigLoaded]) => !isConfigLoaded),
    switchMap(() => this.configService.getFolioConfig()),
    filter(config => !!config),
    map(config => FolioActions.getConfigurationSuccess({ config }))
  ));

  /**
   * Home
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  loadHome$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.loadHome),
    map(() => FolioActions.getConfiguration())
  ));

  /**
   * Library
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  loadLibrary$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.loadLibrary),
    mergeMap(() => {
      return [
        FolioActions.getConfiguration(),
        FolioActions.getLibraryDocs(),
        FolioActions.getUsersFavorites(),
      ];
    })
  ));

  /**
   * Share History
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  loadShareHistory$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.loadShareHistory),
    map(() => FolioActions.getConfiguration())
  ));

  /**
   * Initial kick off of "getting" documents.
   * Once the config is loaded we'll parse the query params and kick off an action to update the store based on those.
   */
  getLibraryDocs$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getLibraryDocs),
    delayWhen(() => this.store.pipe(select(FolioSelectors.isConfigLoaded), filter(isConfigLoaded => isConfigLoaded))),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectQueryParams))),
    map(([_, queryParams]) => {
      const qParam = FilterUtil.getQParam(queryParams);
      return FolioActions.updateStateFromParams({ qParam });
    })
  ));

  /**
   * Update the state based on the current query params.
   * All of the state updating is done in the reducer with the supplied qParam.
   * This effect simply kicks off the actual query action.
   */
  updateStateFromParams$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.updateStateFromParams),
    map((_) => FolioActions.queryLibraryDocs()))
  );

  /**
   * Update the library query params by re-routing to the library with new params.
   * This action will be kicked off by all of the "apply" and "clear"s below which pass in a new qParam to put into the URL.
   * We'll first run it through a cleanUpQParam to remove any defaults or empty filters.
   */
  updateLibraryQueryParam$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.updateLibraryQueryParam),
    map(action => action.qParam),
    map((qParam: FolioLibraryQueryParam) => FilterUtil.cleanUpQParam(DataUtil.clone(qParam))),
    map((qParam: FolioLibraryQueryParam = {}) => {
      if (DataUtil.notEmpty(qParam)) {
        const queryParams = { q: UrlUtil.encode(qParam) };
        this.router.navigate(['/library'], { queryParams, replaceUrl: true });
      } else {
        this.router.navigate(['/library']);
      }
    })
  ), { dispatch: false });

  /**
   * Query for library documents.
   * The state will have an already calculated appliedRequest that we can use for this.
   */
  queryLibraryDocs$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.queryLibraryDocs),
    distinctUntilChanged(),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectAppliedRequest))),
    switchMap(([_, folioDocumentRequest]) => this.documentService.queryFolioDocuments(folioDocumentRequest)),
    map(folioPageResult => FolioActions.queryLibraryDocsSuccess({ folioPageResult })))
  );

  /**
   * Apply filters, free text, sort, etc
   * -----------------------------------
   */

  applyPendingFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.applyPendingFilters),
    map(action => action.pendingFilters),
    map((pendingFilters: PendingFilters) => {
      const qParam     = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      qParam.filters   = pendingFilters.filterKeyOptions;
      qParam.favorites = pendingFilters.onlyFavorites;
      delete qParam.start;
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  applyPendingFreeText$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.applyPendingFreeText),
    map(action => action.freeText),
    map((freeText: string) => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      qParam.text  = freeText ? freeText : null;
      qParam.sort  = 'relevancy';
      delete qParam.start;
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  applySortOption$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.applySortOption),
    map(action => action.sortId),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectBaseSortId))),
    map(([sortId, baseSortId]) => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      delete qParam.start;
      if (sortId !== baseSortId) {
        qParam.sort = sortId;
      } else {
        delete qParam.sort;
      }
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  applyOrClearFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.applyPendingFilters, FolioActions.clearAppliedFilters),
    map(() => FolioActions.hideLibraryFilters())
  ));

  /**
   * Apply paging info
   * -----------------------------------
   */

  getNextLibraryDocs$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getNextLibraryDocs),
    map(_ => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      qParam.start = (qParam.start || 0) + BASE_PAGING_COUNT;
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  getPreviousLibraryDocs$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getPreviousLibraryDocs),
    map(_ => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      qParam.start = Math.max(0, (qParam.start || 0) - BASE_PAGING_COUNT);
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  /**
   * Clear filters, free text, sort, etc
   * -----------------------------------
   */

  clearAppliedFilters$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.clearAppliedFilters),
    map(_ => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      delete qParam.filters;
      delete qParam.favorites;
      delete qParam.start;
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  clearAppliedFilter$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.clearAppliedFilter),
    map(action => action.appliedFilterLabel),
    map((appliedFilterLabel: AppliedFilterLabel) => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      delete qParam.start;

      // remove the given option display from this filter ids options
      qParam.filters[appliedFilterLabel.filterId] =
        qParam.filters[appliedFilterLabel.filterId].filter(v => v !== appliedFilterLabel.optionDisplay);

      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  clearOnlyFavoritesFilter$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.clearOnlyFavoritesFilter),
    map(_ => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      delete qParam.favorites;
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  clearFreeText$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.clearAppliedFreeText),
    map(_ => {
      const qParam = FilterUtil.getQParam(this.activatedRoute.snapshot.queryParams);
      delete qParam.text;
      delete qParam.start;
      if (qParam.sort === 'relevancy') {
        delete qParam.sort;
      }
      return FolioActions.updateLibraryQueryParam({ qParam });
    }))
  );

  downloadDocument$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.downloadDocument),
    map((folioDocument: FolioDocument) => {
      this.auditService.createAudit(AuditActions.DOWNLOAD_DOCUMENT, folioDocument);
      return folioDocument;
    }),
    switchMap((doc: FolioDocument) => this.documentService.getFolioDocumentDownloadLink(DocUtil.getId(doc))),
    filter(url => !!url),
    map(url => window.location.href = url)), { dispatch: false });


  /**
   * Favorites
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  getUsersFavorites$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getUsersFavorites),
    switchMap(() => this.favoritesService.getFavorites()),
    map(favorites => FolioActions.getUsersFavoritesSuccess({ favorites }))
  ));

  toggleFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.toggleFavorite),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectFavorites))),
    map(([{ id }, favorites]) => {
      const isFavorite = favorites?.documentIds?.includes(id);
      return isFavorite ? FolioActions.removeFavorite({ id }) : FolioActions.addFavorite({ id });
    })
  ));

  addFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.addFavorite),
    switchMap(({ id }) => this.favoritesService.addFavorites([id])),
    map(favorites => FolioActions.getUsersFavoritesSuccess({ favorites }))
  ));

  auditAddFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.addFavorite),
    switchMap(({ id }) => this.documentService.getIndexedFolioDocument(id)),
    map(folioDoc => this.auditService.createAudit(AuditActions.FAVORITE_DOCUMENT, folioDoc))
  ), { dispatch: false });

  removeFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.removeFavorite),
    switchMap(({ id }) => this.favoritesService.removeFavorites([id])),
    map(favorites => FolioActions.getUsersFavoritesSuccess({ favorites }))
  ));

  auditRemoveFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.removeFavorite),
    switchMap(({ id }) => this.documentService.getIndexedFolioDocument(id)),
    map(folioDoc => this.auditService.createAudit(AuditActions.UNFAVORITE_DOCUMENT, folioDoc))
  ), { dispatch: false });

  /**
   * Viewer
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  loadViewer$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.loadViewer),
    mergeMap(() => {
      return [
        FolioActions.getConfiguration(),
        FolioActions.getUsersFavorites(),
        FolioActions.getViewerDoc()
      ];
    })
  ));

  loadPublicViewer$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.loadPublicViewer),
    mergeMap(() => {
      return [FolioActions.getPublicViewerDoc()];
    })
  ));

  getViewerDocument$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getViewerDoc),
    delayWhen(() =>
      this.store.pipe(
        select(FolioSelectors.isConfigLoaded),
        filter(isConfigLoaded => isConfigLoaded)
      )
    ),
    distinctUntilChanged(),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectRouteParams))),
    switchMap(([_, { id }]) => this.documentService.getFolioDocument(id)),
    filter(doc => !!doc),
    map(document => FolioActions.getViewerDocSuccess({ document })))
  );

  getPublicViewerDocument$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getPublicViewerDoc),
    withLatestFrom(this.store.pipe(select(FolioSelectors.selectRouteParams))),
    switchMap(([_, { id }]) => this.documentService.getDocumentForPublicLinkId(id)),
    filter(doc => !!doc),
    map(document => FolioActions.getPublicViewerDocSuccess({ document })))
  );

  auditDocumentView$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.getViewerDocSuccess),
    map(({ document }) => this.auditService.createAudit(AuditActions.VIEW_DOCUMENT, document)),
  ), { dispatch: false });

  /**
   * Profile
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */
  loadProfile$ = createEffect(() => this.actions$.pipe(
    ofType(FolioActions.loadProfile),
    mergeMap(() => {
      return [
        FolioActions.getConfiguration()
      ];
    })
  ));

  /**
   * Utility functions
   * ---------------------------------------------------------------------------------------------------------------------------------------
   */

  /**
   * given the action, get the activated route
   */
  static getActivatedRoute(action: any): ActivatedRouteSnapshot {
    const routerState: RouterStateSnapshot = (<RouterNavigationPayload<any>> action.payload).routerState;
    let activatedRoute                     = routerState.root;
    while (activatedRoute.firstChild) {
      activatedRoute = activatedRoute.firstChild;
    }
    return activatedRoute;
  }
}
