import {
  FilterKeyOptionMap,
  FolioDocument,
  FolioDocumentRequest,
  FolioPageResult,
  LibraryFilterConfig,
  FolioConfiguration,
  Favorites,
} from '@core/models';

export interface FolioState {
  user: FolioAuth0User;
  config: FolioConfiguration;
  library: LibraryState;
  viewer: ViewerState;
  favorites: Favorites;
  progress: Progress;
  indexedDocuments: {[s: string]: FolioDocument};
  indexedDocumentNames: {[s: string]: string};
}

export interface FolioAuth0User {
  [s: string]: any;
  accessToken: {
    [s: string]: any;
    permissions: string[];
  };
}

export interface LibraryState {
  folioPageResult: FolioPageResult;
  bulkSelect: BulkSelect;
  layout: LibraryLayout;
  filterPanelShown: boolean;
  baseFilterConfig: LibraryFilterConfig;
  appliedFilterConfig: LibraryFilterConfig;
  baseRequest: FolioDocumentRequest;
  appliedRequest: FolioDocumentRequest;
}

export interface ViewerState {
  document: FolioDocument;
  display: ViewerDisplay;
}

// --------------------------------------------

export enum LibraryLayout {
  CARD_VIEW = 'card-view',
  LIST_VIEW = 'list-view'
}

export interface BulkSelect {
  on: boolean;
  ids: string[];
}

export interface Progress {
  config: FetchProgress;
  libraryDocuments: FetchProgress;
  viewerDocument: FetchProgress;
  favorites: FetchProgress;
}

export enum FetchProgress {
  NONE = 'none',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}

export interface ViewerDisplay {
  presentationMode: boolean;
  zoomScale: number;
  fitToWidth: boolean;
  contentReady?: boolean;
  pageData?: PageData;
}

export interface PageData {
  current: number;
  total: number;
}

// --------------------------------------------

export const initialState: FolioState = {
  user:    null,
  config:  null,
  library: {
    folioPageResult: null,
    bulkSelect:      {
      on:  false,
      ids: []
    },
    layout:              LibraryLayout.CARD_VIEW,
    filterPanelShown:    false,
    baseFilterConfig:    null,
    appliedFilterConfig: null,
    baseRequest:         null,
    appliedRequest:      null
  },
  viewer: {
    document: null,
    display:  {
      presentationMode: false,
      zoomScale:        1,
      fitToWidth:       false,
      pageData:         null
    }
  },
  favorites: null,
  progress:  {
    config:           FetchProgress.NONE,
    libraryDocuments: FetchProgress.NONE,
    viewerDocument:   FetchProgress.NONE,
    favorites:        FetchProgress.NONE,
  },
  indexedDocuments:     null,
  indexedDocumentNames: null
};

// --------------------------------------------

export interface FolioLibraryQueryParam {
  filters?: FilterKeyOptionMap;
  sort?: string;
  start?: number;
  text?: string;
  favorites?: boolean;
}
