import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { UrlUtil } from './core/utils/url.util';
import { Auth0Config } from './core/utils/app.model';
import { DataUtil } from './core/utils/data.util';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigService {

  constructor(private http: HttpClient, private handler: HttpBackend, private authClientConfig: AuthClientConfig) {
  }

  /**
   * Load the authentication configuration json from the publicly available Folio API endpoint
   */
  load(): Promise<void> {
    const url = UrlUtil.folioServerUrl('/api/v1/auth0', {});
    return new Promise<void>((resolve, reject) => {
      this.http = new HttpClient(this.handler);
      this.http.get(url).toPromise().then((config: Auth0Config) => {
        if (UrlUtil.isPublicDocumentLinkPage()) {
          config.httpInterceptor = null;
        }
        config.prompt = 'select_account';
        this.authClientConfig.set(DataUtil.removeNulls(config));
        resolve();
      });
    });
  }
}
