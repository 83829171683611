<ng-container *ngIf="active">
  <div class="header">Confirm delete</div>
  <div class="content">
    <p>Are you sure you want to delete "{{collection.name}}"? Any nested Collections within this Collection will also be deleted.
    </p>
    <p>Though other people will no longer see this collection of grouped content, they will still be able to view individual pieces in the Library.
    </p>
  </div>
  <div class="actions">
    <div class="ui basic primary deny button">
      Cancel
    </div>
    <div class="ui teal ok submit button" (click)="handleDeleteCollectionClick()">
      Delete
    </div>
  </div>
</ng-container>