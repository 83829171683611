import { Component, OnInit } from '@angular/core';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { ofType } from '@ngrx/effects';
import { FolioActions } from '@app/ngrx/actions';
import { filter } from 'rxjs/operators';
import { FolioModalType } from '@core/models/modals.model';
import { CollectionFolderItem } from '@core/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector:    'folio-add-collection-actions-modal',
  templateUrl: './add-collection-actions-modal.component.html',
  styleUrls:   ['../../folio-modal-styles.less']
})
export class AddCollectionActionsModalComponent extends FolioModalComponent implements OnInit {

  public collectionParentId: string;
  public allFolderCollections: CollectionFolderItem[];

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload => payload.modalType === FolioModalType.COLLECTION_ACTIONS)
    ).subscribe(({ show = true, data }) => {
      this.collectionParentId   = data.collectionParentId;
      this.allFolderCollections = data.allFolderCollections;
      this.toggle(show);
    });
  }

  handleNewCollectionClick() {
    this.store.dispatch(FolioActions.toggleModal(
      {
        modalType: FolioModalType.NEW_COLLECTION_FORM,
        data:      {
          collectionParentId:   this.collectionParentId,
          allFolderCollections: this.allFolderCollections
        }
      }));
  }

  handleContentFromLibraryClick() {
    this.router.navigate(['/library'], { state: { bulkSelectOn: true } });
  }

}
