<ng-container *ngIf="active">
  <div class="header">
    {{success ? 'Content added' : 'Add to collection'}}
  </div>
  <div class="content">
    <ng-container *ngIf="success else showForm">
      <folio-icon-message [messageClasses]="'positive'" [iconClasses]="'checkmark triangle'">
        {{selectedDocumentsLength}} piece(s) added to “{{selectedCollection.name}}”
      </folio-icon-message>
    </ng-container>
    <ng-template #showForm>
      <form class="ui form" [formGroup]="form">
        <flow-select-input [label]="'Select collection'"
                           [placeholder]="'Select collection'"
                           [formControlName]="'parentId'">
        </flow-select-input>
      </form>
    </ng-template>
  </div>
  <div class="actions">
    <ng-container *ngIf="success else showFormButtons">
      <div class="ui basic primary deny button">Close</div>
      <div class="ui teal ok submit button" (click)="handleViewCollectionClick()">
        View collection
      </div>
    </ng-container>
    <ng-template #showFormButtons>
      <div class="ui basic primary deny button">Cancel</div>
      <div class="ui teal button" [class.loading]="requestLoader"
           (click)="handleAddToCollectionClick()">
        Add
      </div>
    </ng-template>
  </div>
</ng-container>