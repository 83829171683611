<ng-container *ngIf="active">
  <div class="header">
    Confirm removal
  </div>
  <div class="content">
    <div class="info" style="padding:20px">
    <p>
      Are you sure you want to remove "{{documentName}}" from "{{selectedCollection.name}}"?
    </p>
    <p>Though this piece will be removed from this Collection, it will still be viewable in the Library.</p>
    </div>
  </div>
  <div class="actions">
    <div class="ui basic primary deny button">
      Cancel
    </div>
    <div class="ui teal submit button" (click)="handleConfirmClick()" [ngClass]="{'loading': requestLoader}">
      Remove
    </div>
  </div>
</ng-container>
