import { Component, OnInit } from '@angular/core';
import { FolioModalComponent } from '@shared/folio-modals/folio-modal/folio-modal.component';
import { FormGroup } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { FolioActions } from '@app/ngrx/actions';
import { filter } from 'rxjs/operators';
import { FolioModalType } from '@core/models/modals.model';
import { CollectionFolderItem, CollectionItem, CollectionItemType } from '@core/models';
import { addCollection, updateCollection } from '@app/ngrx/collection/collection.actions';
import { Update } from '@ngrx/entity';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector:    'folio-collection-form-modal',
  templateUrl: './collection-form-modal.component.html',
  styleUrls:   ['./collection-form-modal.component.less']
})
export class CollectionFormModalComponent extends FolioModalComponent implements OnInit {

  public form: FormGroup;
  public formType: 'new' | 'edit';
  public currentSelectedCollection: CollectionItem;
  public collectionParentId: string;
  public allFolderCollections: CollectionFolderItem[];
  public requestLoader: boolean;

  ngOnInit(): void {
    this.actions$.pipe(
      ofType(FolioActions.toggleModal),
      filter(payload =>
        (payload.modalType === FolioModalType.NEW_COLLECTION_FORM || payload.modalType === FolioModalType.UPDATE_COLLECTION_FORM))
    ).subscribe(({ modalType, show = true, data }) => {
      this.formType                  = modalType === FolioModalType.NEW_COLLECTION_FORM ? 'new' : 'edit';
      this.currentSelectedCollection = data.collection;
      this.collectionParentId        = data.collectionParentId;
      this.allFolderCollections      = data.allFolderCollections;
      this.initForm();
      this.toggle(show);
    });
  }

  public initForm() {
    if (this.formType === 'new') {
      this.form = this.collectionsService.generateFormGroup(
        this.allFolderCollections,
        this.collectionParentId,
        'new',
      );
    } else {
      this.form = this.collectionsService.generateFormGroup(
        this.allFolderCollections,
        this.collectionParentId,
        'edit',
        this.currentSelectedCollection
      );
    }
  }

  public handleSubmission() {
    this.form.markAllAsTouched();

    if (!this.form.invalid) {
      if (this.formType === 'new') {
        this.createCollection();
      } else {
        this.updateCollection();
      }
    }

    return false;
  }

  // create collection
  // -----------------------------------------------------------------------------------------------
  private createCollection() {
    const formValue     = this.form.value;
    const newCollection = { ... formValue, documentIds: [], parentId: formValue.parentId ? formValue.parentId : null };

    this.requestLoader = true;
    this.collectionsService.createCollection(newCollection)
      .subscribe((collection: CollectionItem) => {
        this.requestLoader = false;
        this.hide();
        this.store.dispatch(addCollection(
          { collection: { ...collection, type: CollectionItemType.FOLDER, justCreated: true } }
        ));
      });
  }

  // update collection
  // -----------------------------------------------------------------------------------------------
  private updateCollection() {
    const formValue         = this.form.value;
    const updatedCollection = { ... formValue, parentId: formValue.parentId ? formValue.parentId : null };

    this.requestLoader = true;
    this.collectionsService.updateCollection(updatedCollection)
      .subscribe((collection: CollectionItem) => {
        this.requestLoader = false;
        this.hide();
        const updatedCollection: Update<CollectionFolderItem> = {
          id:      collection.id,
          changes: collection
        };
        this.store.dispatch(updateCollection({ collection: updatedCollection }));
      });
  }
}
