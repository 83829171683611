<div class="field" [ngClass]="{'required': isRequired, 'error': !isValid && isTouched}">
    <label>{{label}}</label>
    <div class="ui input">
        <input 
            [name]="id"
            type="text" 
            [minLength]="minLength"
            [maxLength]="maxLength"
            [placeholder]="placeholder"
            [value]="value"
            (input)="onChangeValue($event)"
        >
    </div>
</div>